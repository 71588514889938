import { FC } from 'react';
import { EntityMapping } from '../../config/entity-mapping';
import { Teaser } from '../../types/global';

interface TeaserRendererProps {
  teaser: Teaser;
  teaserLayout: string;
}

export const TeaserRenderer: FC<TeaserRendererProps> = ({
  teaser,
  teaserLayout,
}) => {
  const teaserName = `Teaser_${teaserLayout}`;

  const Component = EntityMapping[teaserName] || null;

  if (!Component) {
    console.warn('[TeaserRenderer] Teaser not found', teaserName);
    return null;
  }

  return (
    <Component
      title={
        teaser.__typename === 'NodeArticle'
          ? teaser.teaserTitle || teaser.headline
          : teaser.title
      }
      url={teaser.url}
      image={teaser.teaserImage}
      text={teaser.teaserText}
      layout={teaserLayout}
    />
  );
};
