import { FC, useContext, useState } from 'react';
import { ParagraphTeaserListFragment } from '../../../generated/types';
import {
  Box,
  Button,
  ButtonProps,
  Icon,
  useBreakpoint,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { TeaserRenderer } from '../../Teaser/TeaserRenderer';

// CSS
import 'swiper/css';
import 'swiper/css/pagination';

import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import { IoChevronBack } from '@react-icons/all-files/io5/IoChevronBack';
import { Pagination } from 'swiper';
import { PageContext } from '../../../context/PageContext';

interface TeaserListProps {
  data: ParagraphTeaserListFragment;
}

type SliderButtonProps = ButtonProps & {
  buttonType: 'next' | 'prev';
  swiperRef: SwiperClass | null;
};

const SliderButton: FC<SliderButtonProps> = ({
  buttonType,
  swiperRef,
  ...props
}) => {
  const onClick = () => {
    buttonType === 'next' ? swiperRef?.slideNext() : swiperRef?.slidePrev();
  };

  return (
    <Button
      pos="absolute"
      cursor="pointer"
      onClick={onClick}
      top="calc(50% - 25px)"
      transform="translateY(-50%)"
      zIndex={9}
      left={
        buttonType === 'next'
          ? 'auto'
          : {
              base: 0,
              md: '-60px',
            }
      }
      right={
        buttonType === 'prev'
          ? 'auto'
          : {
              base: 0,
              md: '-60px',
            }
      }
      color="white"
      bg="primary"
      fontSize="4xl"
      borderRadius="sm"
      h="40px"
      w="40px"
      _hover={{
        bg: 'primaryDark',
      }}
      _active={{
        bg: 'primaryDarker',
      }}
      {...props}
    >
      <Icon as={buttonType === 'next' ? IoChevronForward : IoChevronBack} />
    </Button>
  );
};

export const TeaserListSlider: FC<TeaserListProps> = ({ data }) => {
  const pageContext = useContext(PageContext);

  const filteredTeasers = data.teasers.filter(
    (teaser) => teaser.id !== pageContext?.entity?.id
  );

  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  const [swiperData, setSwiperData] = useState<{
    isEnd: boolean;
    isStart: boolean;
  }>({
    isEnd: true,
    isStart: true,
  });
  let slidesPerView =
    useBreakpointValue({
      base: 1,
      md: data.teaserLayout === 'image_title' ? 4 : 3,
    }) || 1;

  if (slidesPerView > filteredTeasers.length)
    slidesPerView = filteredTeasers.length;
  const hasMoreSlides = filteredTeasers.length > slidesPerView;

  const updateSwiperData = (swiper: SwiperClass) => {
    setSwiperData({
      isEnd: swiper.isEnd,
      isStart: swiper.isBeginning,
    });
  };

  return (
    <Box
      className={data.__typename}
      pos="relative"
      __css={{
        '.swiper': {
          paddingBottom: '50px',
        },
        '.swiper-pagination-bullet-active': {
          bg: 'primary',
        },
      }}
    >
      <Swiper
        onSlideChange={(swiper) => {
          updateSwiperData(swiper);
        }}
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
        onSwiper={(swiper) => {
          setSwiperRef(swiper);
          updateSwiperData(swiper);
        }}
        spaceBetween={20}
        slidesPerView={slidesPerView}
      >
        {filteredTeasers.map((teaser, i) => (
          <SwiperSlide key={i}>
            <TeaserRenderer teaser={teaser} teaserLayout={data.teaserLayout} />
          </SwiperSlide>
        ))}
      </Swiper>
      {hasMoreSlides && (
        <>
          <SliderButton
            buttonType="next"
            swiperRef={swiperRef}
            disabled={swiperData.isEnd}
          />
          <SliderButton
            buttonType="prev"
            swiperRef={swiperRef}
            disabled={swiperData.isStart}
          />
        </>
      )}
    </Box>
  );
};

export const TeaserList: FC<TeaserListProps> = ({ data }) => {
  const pageContext = useContext(PageContext);

  if (data.teaserLayout !== 'list') {
    return <TeaserListSlider data={data} />;
  }

  return (
    <VStack spacing={9}>
      {data.teasers
        .filter((teaser) => teaser.id !== pageContext?.entity?.id)
        .map((teaser, i) => (
          <Box key={i} width="100%">
            <TeaserRenderer teaser={teaser} teaserLayout={data.teaserLayout} />
          </Box>
        ))}
    </VStack>
  );
};
